<template>
  <div v-if="id" :id="id" class="bg-cream cantacts">
    <b-row
      id="contact-info"
      class="justify-content-center text-center invisible"
      style="width: 100%; margin: 0px"
    >
      <b-col cols="12">
        <h2 class="tepa-h2 border-red">{{ contacts.header[0].text }}</h2>
      </b-col>
      <b-col cols="10">
        <b-row
          class="text-lg-left text-center mt-lg-5"
          style="justify-content: space-between"
        >
          <b-col
            cols="12"
            lg="auto"
            md="12"
            v-for="(section, index) in contacts.section"
            :key="index"
            class="space-section"
          >
            <h3 class="tepa-h3 mb-lg-3 mb-2 mt-3 mt-lg-0">
              {{ section.title[0].text }}
            </h3>
            <prismic-rich-text class="tepa-p" :field="section.info" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <Map id="contact-map" class="invisible" :url="contacts.map.url" />
  </div>
</template>

<script>
import $ from "jquery";
export default {
  components: {
    Map: () => import(`@/components/Map`),
  },
  data() {
    return {
      contacts: [],
      id: null,
    };
  },
  computed: {
    ref() {
      return this.$store.getters["cms/ref"];
    },
  },
  mounted() {
    this.getContacts();
    $(document).scroll(function () {
      const height = $(window).scrollTop() + $(window).height();
      if ($(`#contact-info`).length > 0) {
        if (height > $(`#contact-info`).offset().top) {
          $(`#contact-info`)
            .addClass("animate__animated animate__fadeInUp")
            .removeClass("invisible");
          $("#contact-map").css("--animate-duration", "0.5s");
          $(`#contact-map`)
            .addClass("animate__animated animate__fadeInUp animate__delay-1s")
            .removeClass("invisible");
        }
      }
    });
  },
  methods: {
    async getContacts() {
      const params = {
        ref: this.ref,
        q: `[[at(document.tags, ["Contact"])]]`,
        orderings: "[my.paragraph.priority]",
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        this.contacts = res.data.results[0].data;
        this.id = this.contacts.id[0].text;
        console.log(this.contacts);
      }
    },
  },
};
</script>

<style scoped>
.tepa-h2 {
  padding-bottom: 15px;
  display: inline-block;
  border-bottom: 2px solid;
}
>>> a {
  color: #740b1b !important;
}
>>> p {
  margin: 0px;
}
.tepa-p {
  font-family: Open Sans;
  white-space: break-spaces;
}
.cantacts {
  padding: 80px 0;
}
@media only screen and (max-width: 992px) {
  .cantacts {
    padding: 40px 0 30px;
  }
}
</style>